<script lang='ts'>
	import { fade } from 'svelte/transition';
	import Title from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import videoConfig from '@/video.config';
	import MenuPreviewItem from '@/lib/components/menu/MenuPreviewItem.svelte';
	import { menu, promocodes, promoPopupCode, promoValues, screen } from '@/stores';
	import Triangle from '@/assets/Triangle.svelte';
	import Cross from '@/assets/Cross.svelte';
	import { isVideoRecorded } from '@/utils';
	import { get } from 'svelte/store';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';

	const showOrRecord = (index: number, recorded: boolean) => {
		recorded ? screen.playVideo(index) : screen.record(index);
		menu.hide();
	};

	const showPromoPopup = (value: number) => {
		promoPopupCode.showValue(value);
		eventBus.emit(EVENTS.PROMO_POPUP, true);
	};
</script>

<div class='menu-overlay' transition:fade on:click={menu.hide}></div>
<div class='menu' transition:fade>
	<div class='close' on:click={menu.hide}>
		<Cross />
	</div>
	<Title -menu-title -bold>{@html texts.menu.title1}</Title>
	<div class='list'>
		{#each videoConfig.video as video, index}
			{@const recorded = isVideoRecorded(index)}
			<MenuPreviewItem src='{video.menuPreview}' on:click={()=>showOrRecord(index, recorded)}>
				{#if recorded}
					<div class='overlay'>
						<div class='circle'>
							<Triangle />
						</div>
					</div>
				{/if}
			</MenuPreviewItem>
		{/each}
	</div>
	<div class='_title'>
		<Title -menu-title -bold>{@html texts.menu.title2}</Title>
	</div>
	<div class='list'>
		{#each $promoValues as value}
			{@const active = !!get(promocodes).get(value)}
			{#if active}
				<MenuPreviewItem on:click={()=>showPromoPopup(value)}>
					<Title -promocode-value -center -bold -red>{value}%</Title>
				</MenuPreviewItem>
			{:else}
				<MenuPreviewItem disabled>
					<Title -promocode-value -center -bold -beige>{value}%</Title>
				</MenuPreviewItem>
			{/if}
		{/each}
	</div>
</div>

<style lang='scss'>
  .menu-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $overlay;
  }

  .menu {
    padding: 44px 28px 31px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $beige-a;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid $black;

    @include breakpoint-up($tablet) {
      padding-top: px-to-rem-tablet(100px);
    }

    @include desktop-breakpoint-up {
      width: 540px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  ._title {
    margin-top: 54px;
  }

  .close {
    width: 49px;
    height: 49px;
    position: absolute;
    top: 18px;
    right: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $black;
    border-radius: 49px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(100px);
      height: px-to-rem-tablet(100px);
      border-radius: px-to-rem-tablet(100px);
    }
  }

  .list {
    margin-top: 19px;
    display: flex;
    column-gap: 11px;
  }

  .overlay {
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(#404040, .8);

    .circle {
      width: 70px;
      height: 70px;
      padding-left: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 70px;
      border: 1px solid $black;
      background: $white;
      box-sizing: border-box;

      @include breakpoint-up($tablet) {
        width: px-to-rem-tablet(140px);
        height: px-to-rem-tablet(140px);
        border-radius: px-to-rem-tablet(70px);
      }
    }
  }

  .promo-value {
    &.-disable {

    }
  }
</style>
