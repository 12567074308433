<script lang='ts'>
	import { fade } from 'svelte/transition';
	import RoundButton from '@/lib/components/RoundButton.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import ArrowBack from '@/assets/ArrowBack.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';

	const back = () => {
		eventBus.emit(EVENTS.BACK_TO_SHARED_VIDEO_LANDING);
	};
</script>

<div class='shared-video-menu' transition:fade>
	<div class='_button'>
		<RoundButton big on:click={back}>
			<ArrowBack />
			<Text -header-title2 -center -white slot='text'>Назад</Text>
		</RoundButton>
	</div>
</div>

<style lang='scss'>
  .shared-video-menu {
    height: 250px;
    padding-bottom: 46px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.015709) 4.31%, rgba(0, 0, 0, 0.0425) 9.05%, rgba(0, 0, 0, 0.085) 13.11%, rgba(0, 0, 0, 0.153) 16.7%, rgba(0, 0, 0, 0.223244) 19.71%, rgba(0, 0, 0, 0.365194) 26.53%, rgba(0, 0, 0, 0.4675) 32.25%, rgba(0, 0, 0, 0.558527) 37.5%, rgba(0, 0, 0, 0.614844) 41.23%, rgba(0, 0, 0, 0.669196) 45.41%, rgba(0, 0, 0, 0.721707) 50.18%, rgba(0, 0, 0, 0.763802) 55.78%, rgba(0, 0, 0, 0.7905) 60.2%, rgba(0, 0, 0, 0.809401) 65.84%, rgba(0, 0, 0, 0.8245) 72.55%, rgba(0, 0, 0, 0.8415) 81.2%, rgba(0, 0, 0, 0.85) 94.81%);
    box-sizing: border-box;

    @include desktop-breakpoint-up {
      width: calc(10 / 16 * 100vh);
      margin-left: auto;
      margin-right: auto;
    }
  }

  ._button {
    margin-top: auto;
  }
</style>
