<div class='film'>

</div>

<style lang='scss'>
  .film {
    width: 12px;
    height: 100%;
    background: url("/film.png") repeat-y;
    background-size: 12px auto;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(40px);
      background: url("/film2.png") repeat-y;
      background-size: px-to-rem-tablet(40px) auto;
    }
  }
</style>
