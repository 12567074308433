<script lang='ts'>
	import Copy from '@/assets/Copy.svelte';
	import texts from '@/texts';
	import { isOnlineVersion, promocodes } from '@/stores';

	export let style = null;
	export let code;
	let interval;
	let copied = false;
	let noCopy = Object.keys($$restProps).includes('-no-copy');

	const copy = async () => {
		if (noCopy) return;

		await navigator.clipboard.writeText(code);
		copied = true;
		interval && clearTimeout(interval);
		interval = setTimeout(() => {
			copied = false;
		}, 2000);
	};
</script>

<section class='promocode' {style}>
	<button class='code' class:-no-copy={noCopy} disabled='{!code}' on:click={copy}>
		<span class='text'>
			{code ?? 'Загрузка...'}
		</span>
		{#if code && !noCopy}
			<Copy />
		{/if}
	</button>
	<div class='_caption'>
		{#if copied}
			<div class='copy-informer'>промокод скопирован</div>
		{/if}
		{#if code}
			{#if $isOnlineVersion}
				{@html texts.promo.captions[promocodes.getIndex(code)] ?? texts.promo.captionOffline}
			{:else}
				{@html texts.promo.captionOffline}
			{/if}
		{/if}
	</div>
</section>

<style lang='scss'>
  .promocode {
    margin-top: 33px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(47px);
    }
  }

  .code {
    @include reset-button;

    width: auto;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 80px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: $red-a;
    box-sizing: border-box;
    background: $white;
    border-radius: 16px;

    @include breakpoint-up($tablet) {
      height: px-to-rem-tablet(101px);
      padding-right: px-to-rem-tablet(150px);
      font-size: px-to-rem-tablet(56px);
    }

    :global(svg) {
      margin: auto;
      position: absolute;
      right: 19px;
      top: 0;
      bottom: 0;

      @include breakpoint-up($tablet) {
        right: px-to-rem-tablet(33px);
      }
    }

    &:disabled {
      pointer-events: none;

      > .text {
        opacity: 0.35;
      }
    }

    &.-no-copy {
      width: auto;
      padding-right: 20px;

      @include breakpoint-up($tablet) {
        height: px-to-rem-tablet(101px);
        font-size: px-to-rem-tablet(56px);
      }
    }
  }

  ._caption {
    width: 299px;
    margin-top: 27px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.01em;
    color: $gray-b;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(530px);
      margin-top: px-to-rem-tablet(64px);
      font-size: px-to-rem-tablet(28px);
      line-height: 130%;
    }
  }

  .copy-informer {
    position: absolute;
    top: -24px;
    left: 0;
    right: 0;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.02em;
    text-align: center;
  }
</style>
