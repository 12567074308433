<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import Container from '@/lib/components/Container.svelte';
	import Legal from '@/lib/components/Legal.svelte';
	import MadeByGroznov from '@/lib/components/MadeByGroznov.svelte';
	import Button from '@/lib/components/Button.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Image from '@/lib/components/Image.svelte';

	const mainPage = () => {
		location.href = `${location.protocol}//${location.host}`;
	};
</script>

<Screen>
	<Container -grow-max>
		<div class='_image'>
			<Image src='/404.png' />
		</div>
		<div class='_title'>
			<Text -popup-title -center -bold>{@html texts.P404.title}</Text>
		</div>
		<div class='_button'>
			<Button -small on:click|once={mainPage}>На главную</Button>
		</div>
		<div class='_legal'>
			<Legal />
		</div>
		<div class='_made-by-groznov'>
			<MadeByGroznov />
		</div>
	</Container>
</Screen>

<style lang='scss'>
  ._image {
    width: 113px;
    margin-top: 12vh;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(198px);
      margin-top: 20vh;
    }

    :global(img) {
      width: 100%;
    }
  }

  ._title {
    margin-top: 40px;

    @include breakpoint-up($tablet) {
      margin-top: 70px;
    }
  }

  ._button {
    margin-top: auto;
    margin-bottom: 65px;

    @include breakpoint-up($tablet) {
      margin-bottom: 117px;
    }
  }

  ._legal {
  }

  ._made-by-groznov {
    margin-top: 1rem;
    margin-bottom: 16px;

    @include breakpoint-up($tablet) {
      margin-bottom: 50px;
    }
  }
</style>
