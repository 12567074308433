<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Container from '@/lib/components/Container.svelte';
	import Button from '@/lib/components/Button.svelte';
	import Legal from '@/lib/components/Legal.svelte';
	import Triangle from '@/assets/Triangle.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import ImageSlides from '@/lib/components/ImageSlides.svelte';
	import { getCurrentVideoConfig } from '@/utils';
	import MadeByGroznov from '@/lib/components/MadeByGroznov.svelte';

	const goToMainPage = () => {
		location.href = `${location.protocol}//${location.host}`;
	};

	const goWatchVideo = () => {
		eventBus.emit(EVENTS.SHARED_VIDEO);
	};
</script>

<Screen>
	<Container -grow-max>
		<div class='_title'>
			<Text -popup-title -bold>
				{@html texts.sharedVideo.title}
			</Text>
		</div>
		<button class='video-preview' on:click|once={goWatchVideo}>
			<ImageSlides images='{getCurrentVideoConfig().preview}' />
			<span class='overlay'></span>
			<span class='play'>
				<span class='circle'>
					<Triangle />
				</span>
				<Text -popup-title -white -center -bold>Смотреть</Text>
			</span>
		</button>
		<div class='_caption'>
			<Text -popup-title -bold>
				{@html texts.sharedVideo.caption}
			</Text>
		</div>
		<div class='_button' on:click|once={goToMainPage}>
			<Button -small>Озвучить мультфильм</Button>
		</div>
		<div class='_legal'>
			<Legal />
		</div>
		<div class='_made-by-groznov'>
			<MadeByGroznov />
		</div>
	</Container>
</Screen>

<style lang='scss'>
  ._title {
    margin-top: 20px;

    @include breakpoint-up($tablet) {
      margin-top: 47px;
    }
  }

  ._caption {
    margin-top: 21px;
    margin-bottom: 21px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(67px);
      margin-bottom: px-to-rem-tablet(67px);
    }
  }

  ._button {
    margin-top: 38px;
    margin-bottom: 76px;

    @include breakpoint-up($tablet) {
      margin-top: auto;
      margin-bottom: px-to-rem-tablet(117px);
    }
  }

  ._legal {
    margin-top: auto;

    @include breakpoint-up($tablet) {
      margin-top: 0;
    }
  }

  ._made-by-groznov {
    margin-top: 1rem;
    margin-bottom: 16px;

    @include breakpoint-up($tablet) {
      margin-bottom: 50px;
    }
  }

  .video-preview {
    @include reset-button;

    max-height: 40vh;
    margin-top: 19px;
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    overflow: hidden;

    @include breakpoint-up($tablet) {
      min-height: px-to-rem-tablet(500px);
      max-height: 32vh;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: $overlay2;
    }

    .play {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;

      .circle {
        width: 70px;
        height: 70px;
        margin-bottom: 21px;
        padding-left: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 70px;
        background: $beige-a;
        box-sizing: border-box;

        @include breakpoint-up($tablet) {
          width: px-to-rem-tablet(164px);
          height: px-to-rem-tablet(164px);
          border-radius: px-to-rem-tablet(164px);
        }
      }
    }

    :global(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
