<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 34 33'>
	<path fill='#FF335F'
				d='M15.005 6.703a1.5 1.5 0 0 0 0-3v3Zm11.504 11.505c0 6.354-5.15 11.504-11.504 11.504v3c8.01 0 14.504-6.494 14.504-14.504h-3ZM15.005 29.712C8.65 29.712 3.5 24.562 3.5 18.208h-3c0 8.01 6.494 14.504 14.505 14.504v-3ZM3.5 18.208c0-6.354 5.15-11.505 11.505-11.505v-3C6.994 3.703.5 10.197.5 18.208h3Zm26.01 0c0-2.814-.566-4.997-1.46-6.783-.886-1.774-2.058-3.078-3.142-4.161l-2.12 2.12c.997.998 1.905 2.035 2.58 3.382.667 1.335 1.141 3.053 1.141 5.442h3Z' />
	<path fill='#FF335F' d='m21.382 3.38 10.143 5.857-10.143 5.856V3.381Z' />
</svg>

<style lang='scss'>
  svg {
    width: 34px;
    height: 33px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(56px);
      height: px-to-rem-tablet(55px);
    }

    @include desktop-breakpoint-up {
      width: 33px;
    }
  }
</style>
